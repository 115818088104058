export const routes = {
    root: '/',
    uiKit: '/ui-kit',
    allBWICs: '/bwic-monitor',
    allBWICsOld: '/all-bwics',
    myBWICs: '/my-bwics',
    sellerBuy: '/my-bwics/buy',
    sellerSell: '/my-bwics/sell',
    allToAll: '/bwic-monitor/all-to-all',
    newBWIC: '/new-bwic',
    newBWICCreated: '/new-bwic/done',

    dealers: '/dealers',
    editContacts: '/dealers/edit-all',
    importContacts: '/dealers/import-contacts',

    login: '/sign-in',
    logout: '/sign-out',
    forgotPassword: '/forgot-password',
    forgotPasswordFinished: '/forgot-password/finished',
    setPassword: '/set-password/:result(finished|expired)?',
    setPasswordFinished: '/set-password/finished',
    setPasswordExpired: '/set-password/expired',
    signUp: '/signup',
    signUpConfirm: '/signup-confirm',
    verifyIdentityRequest: '/verify-identity',
    verifyIdentitySubmit: '/verify-identity/submit',

    arrangersClientSignUp: '/arrangers-client/signup/:token',
    arrangersClientSignUpUrl: function (token) {
        return this.arrangersClientSignUp
            .replace(':token', token)

    },
    arrangersClientSignUpConfirm: '/arrangers-client/signup-confirm',

    internal: '/error',
    notFound: '/not-found',
    forbidden: '/forbidden',

    portfolio: '/portfolio',
    newPortfolio: '/portfolio/new',
    editPortfolio: '/portfolio/edit/:referenceName',
    editPortfolioUrl: function (referenceName) {
        return this.editPortfolio.replace(':referenceName', referenceName);
    },
    portfolioDocuments: '/portfolio/documents',

    privacyPolicy: '/privacy-policy',
    termsAndConditions: '/terms-and-conditions',
    ATSRulebook: '/ats-rulebook',

    disclaimers: '/disclaimers',
    disclaimersPlatform: '/disclaimers/platform-disclaimer',
    disclaimersPortfolio: '/disclaimers/portfolio-disclaimer',
    disclaimersEval: '/disclaimers/eval-disclaimer',

    manageBuildVersion: '/build',
    manageCompanies: '/manage/companies',
    manageCompanyMembers: '/manage/companies/members',
    manageClearRedisState: '/manage/clear-redis-state',
    addCompany: '/manage/companies/create-company',
    editCompany: '/manage/companies/:referenceName',
    editCompanyUrl: function (referenceName) {
        return this.editCompany.replace(':referenceName', referenceName);
    },
    manageCompaniesCreateUser: '/manage/companies/create-user',
    manageCompaniesEditUser: '/manage/companies/edit-user/:userId(\\d+)',
    manageCompaniesEditUserUrl: function (userId) {
        return this.manageCompaniesEditUser.replace(':userId(\\d+)', userId);
    },
    manageImportSecurities: '/manage/securities-import',
    manageEmails: '/manage/emails',
    manageTokens: '/manage/tokens',

    // CLO Managers
    manageCloManagers: '/manage/clo-managers',
    manageCloManager: '/manage/clo-managers/:referenceName?/:tab(overview|additionalInformation|deals|cloTeam|edit|analytics)?',
    manageCloManagerUrl: function (referenceName, tab) {
        return this.manageCloManager
            .replace(':referenceName?', referenceName || '')
            .replace(
                ':tab(overview|additionalInformation|deals|cloTeam|edit|analytics)?',
                tab || '',
            )
            .replace('//', '');
    },

    // K-Watch News
    manageNewsPage: '/manage/news',
    manageNews: '/manage/news/:referenceName?/:tab(view|edit)?',
    manageNewsUrl: function (referenceName, tab) {
        return this.manageNews
            .replace(':referenceName?', referenceName || '')
            .replace(
                ':tab(view|edit)?',
                tab,
            )
            .replace('//', '/')
    },
    manageCreateNews: '/manage/create-news',

    // Banks
    manageBanks: '/manage/banks',
    manageBank: '/manage/banks/:referenceName?/:tab(edit|contacts-and-events|primary|secondary|settlement-accounts|analytics)?',
    manageBanksUrl: function(referenceName, tab) {
        return this.manageBank
            .replace(':referenceName?', referenceName || '')
            .replace(
                ':tab(edit|contacts-and-events|primary|secondary|settlement-accounts|analytics)?',
                tab || '',
            )
            .replace('//', '');
    },

    // parsed BWICS
    manageParsedBwics: '/manage/parsed-bwics',
    newParsedBWIC: '/new-parsed-bwic',
    editParsedBWIC: '/edit-parsed-bwic/:referenceName',
    editParsedBWICUrl: function (referenceName) {
        return this.editParsedBWIC.replace(':referenceName', referenceName);
    },

    // profile
    profile: '/profile',
    profilePersonalInfo: '/profile/personal-info',
    profileCompanyInformation: '/profile/company-information',
    profilePasswordSettings: '/profile/password-settings',
    tfaSettings: '/profile/two-factor-authentication-settings',
    profileTraders: '/profile/traders',
    profileTradingLimits: '/profile/trading-limits/:tab(company-limits|settlement-agent-limits|settlement-contacts)?',
    profileTradingLimitsTab: function(tab){
        return this.profileTradingLimits
            .replace(":tab(company-limits|settlement-agent-limits|settlement-contacts)?", tab || '/')
            .replace("//", "");
    },
    profileNotifications: '/profile/notifications/:tab(dashboard|bwicMonitor|myBwics|issuanceMonitor|cloManagers|portfolios|dealerInventory|banks|arrangerPipeline)?',
    profileNotificationsTab: function(tab) {
        return this.profileNotifications
            .replace(':tab(dashboard|bwicMonitor|myBwics|issuanceMonitor|cloManagers|portfolios|dealerInventory|banks|arrangerPipeline)?', tab || '/')
            .replace("//", "");
    },
    subscriptions: '/profile/subscriptions/:tab?',
    subscriptionsTab: function (tab = '') {
        return this.subscriptions
            .replace(':tab?', tab || '/')
            .replace("//", "");
    },
    // seller admin
    profileManageDealers: '/profile/manage-dealers',
    profileManagePlatformUsers: '/profile/manage-platform-users',
    profileManageCreateNewUser: '/profile/manage-platform-users/new-user',
    profileManageEditUserRequest: '/profile/manage-platform-users/edit-user-request/:userRequestId(\\d+)',
    profileManageEditUserRequestUrl: function (userRequestId) {
        return this.profileManageEditUserRequest.replace(':userRequestId(\\d+)', userRequestId);
    },
    profileManageEditUser: '/profile/manage-platform-users/edit-user/:userId(\\d+)',
    profileManageEditUserUrl: function (userId) {
        return this.profileManageEditUser.replace(':userId(\\d+)', userId);
    },
    // subscriptions
    upgradeSubscription: '/subscription/update',
    downgradeSubscription: '/subscription/downgrade',

    // bidding
    bidding: '/bwic/:referenceName/:tab(bidding|dealers)?',
    biddingUrl: function (referenceName, tab = '') {
        return this.bidding
            .replace(':referenceName', referenceName)
            .replace(':tab(bidding|dealers)?', tab)
            .replace(/\/$/, '');
    },

    blotter: '/blotter',
    blotterTardes: '/blotter/trades',
    blotterSettlementAccounts: '/blotter/settlement-accounts/:settlementAccountId(\\d+)?',
    blotterSettlementAccountsUrl: function () {
        return this.blotterSettlementAccounts.replace('/:settlementAccountId(\\d+)?', '');
    },
    blotterSettlementAccountDetailsUrl: function (settlementAccountId) {
        return this.blotterSettlementAccounts.replace(':settlementAccountId(\\d+)?', settlementAccountId);
    },

    dashboard: '/dashboard',

    news: '/k-watch-news/:newsReferenceName?',
    newsUrl: function(newsReferenceName) {
        return this.news
            .replace(':newsReferenceName?', newsReferenceName || '')
            .replace('//', '');
    },

    trades: '/trades',

    clientsCompanies: '/clients/companies', // obsolete
    clients: '/clients',
    clientsSettlementAccount: '/clients/settlement-accounts/:companyId(\\d+)/:settlementAccountId(\\d+)?',
    clientsSettlementAccountUrl: function (companyId) {
        return this.clientsSettlementAccount
            .replace(':companyId(\\d+)', companyId)
            .replace('/:settlementAccountId(\\d+)?', '');
    },
    clientsSettlementAccountDetailsUrl: function (companyId, settlementAccountId) {
        return this.clientsSettlementAccount
            .replace(':companyId(\\d+)', companyId)
            .replace(':settlementAccountId(\\d+)?', settlementAccountId)
    },
    createClientsSettlementAccount: '/clients/settlement-accounts/:companyId(\\d+)/create',
    createClientsSettlementAccountUrl: function (companyId) {
        return this.createClientsSettlementAccount.replace(':companyId(\\d+)', companyId);
    },
    editClientsSettlementAccount: '/clients/settlement-accounts/:companyId(\\d+)/:settlementAccountId(\\d+)?/edit',
    editClientsSettlementAccountUrl: function (companyId, settlementAccountId) {
        return this.editClientsSettlementAccount
            .replace(':companyId(\\d+)', companyId)
            .replace(':settlementAccountId(\\d+)?', settlementAccountId)
    },

    // AMR
    auction: `${process.env.REACT_APP_AMR_ENDPOINT}/:dealReferenceName/:amrReferenceName/:trancheReferenceName`,
    auctionUrl: function (dealReferenceName, amrReferenceName, trancheReferenceName) {
        return this.auction
            .replace(':dealReferenceName', dealReferenceName)
            .replace(':amrReferenceName', amrReferenceName)
            .replace(':trancheReferenceName', trancheReferenceName);
    },
    AMRPipeline: '/issuance-monitor',
    transactionDetail: '/issuance-monitor/deals/:dealReferenceName/transactions/:transactionReferenceName/:tab(details|classes|documents|deal-overview|deal-structure|target-portfolio|analytics|iois|invited-clients|comparative-metrics)?',
    transactionDetailUrl: function (transactionReferenceName, dealReferenceName, tab) {
        return this.transactionDetail
            .replace(':transactionReferenceName', transactionReferenceName || '')
            .replace(':dealReferenceName', dealReferenceName || '')
            .replace(
                ':tab(details|classes|documents|deal-overview|deal-structure|target-portfolio|analytics|iois|invited-clients|comparative-metrics)?',
                tab || '',
            )
            .replace('//', '');
    },
    transactionLimitedAccess: '/issuance-monitor/deals/limited-access',
    issuanceMonitorWithNoData: '/issuance-monitor/no-data',
    transactionClass: '/issuance-monitor/deals/:dealReferenceName/transactions/:transactionReferenceName/:classReferenceName?/:tab?',
    transactionClassUrl: function (transactionReferenceName, dealReferenceName, classReferenceName, tab) {
        return this.transactionClass
            .replace(":transactionReferenceName", transactionReferenceName)
            .replace(":dealReferenceName", dealReferenceName || "")
            .replace(":classReferenceName?", classReferenceName)
            .replace(":tab?", tab || "/")
            .replace("//", "");
    },
    transactionHistory: '/issuance-monitor/deals/:dealReferenceName/transactions/:transactionReferenceName/history/:version?/:tab(details|deal-overview|classes|documents|deal-structure|target-portfolio)?',
    transactionHistoryUrl: function (transactionReferenceName, dealReferenceName, tab, version) {
        return this.transactionHistory
            .replace(':transactionReferenceName', transactionReferenceName || '')
            .replace(':dealReferenceName', dealReferenceName || '')
            .replace(':version?', version || '/')
            .replace(':tab(details|deal-overview|classes|documents|deal-structure|target-portfolio)?', tab || '')
            .replace('//', '');
    },

    inventory: '/inventory',
    inventoryDocuments: '/inventory/documents',
    inventoryEdit: '/inventory/edit',
    adminInventoryEdit: '/inventory/edit/:companyId(\\d+)',
    adminInventoryEditUrl: function (brokerDealerCompanyId) {
        return this.adminInventoryEdit.replace(':companyId(\\d+)', brokerDealerCompanyId);
    },

    resources: '/resources',
    resourcesWebinarGeneral: '/resources/webinar',
    resourcesWebinar: '/resources/webinar/:year(\\d+)?',
    resourcesWebinarUrl: function(year) {
        return this.resourcesWebinar.replace(':year(\\d+)?', year);
    },
    resourcesSearch: '/resources/search',
    resourcesCategory: '/resources/:categoryId(\\d+)',
    resourcesCategoryUrl: function (categoryId) {
        return this.resourcesCategory.replace(':categoryId(\\d+)', categoryId);
    },
    resourseSection: '/resources/:categoryId(\\d+)/:sectionId(\\d+)',
    resourseSectionUrl: function (categoryId, sectionId) {
        return this.resourseSection
            .replace(':categoryId(\\d+)', categoryId)
            .replace(':sectionId(\\d+)', sectionId)
    },
    resourseArticle: '/resources/:categoryId(\\d+)/:sectionId(\\d+)/:articleId(\\d+)',
    resourseArticleUrl: function (categoryId, sectionId, articleId) {
        return this.resourseArticle
            .replace(':categoryId(\\d+)', categoryId)
            .replace(':sectionId(\\d+)', sectionId)
            .replace(':articleId(\\d+)', articleId)
    },

    downloadStatic: "/document/:documentName",

    eval: "/eval",


    dealsCommon: '/deals',
    deals: "/deals/:dealReferenceName?/:tab(overview|dealDetail|classDetail|allClasses|documents|amrInfo)?/:classReferenceName?",
    dealsUrl: function (dealReferenceName, tab, classReferenceName) {
        return this.deals
            .replace(':dealReferenceName?', dealReferenceName || '')
            .replace(':tab(overview|dealDetail|classDetail|allClasses|documents|amrInfo)?', tab || '')
            .replace(':classReferenceName?', classReferenceName || '')
            .replace('//', '');
    },

    editDeal: "/deals/:dealReferenceName?/:tab(dealDetail|documents)?/edit",
    editDealUrl: function (dealReferenceName, tab) {
        return this.editDeal
            .replace(':dealReferenceName?', dealReferenceName || '')
            .replace(':tab(dealDetail|documents)?', tab || '')
            .replace('//', '');
    },

    dealAmrInfo: "/deals/:dealReferenceName?/amrInfo/:transactionReferenceName/",
    dealAmrInfoUrl: function (dealReferenceName, transactionReferenceName,) {
        return this.dealAmrInfo
            .replace(':dealReferenceName?', dealReferenceName || '')
            .replace(':transactionReferenceName', transactionReferenceName || '')
            .replace('//', '');
    },

    dealAmrInfoClass: "/deals/:dealReferenceName/amrInfo/:transactionReferenceName/:classReferenceName",
    dealAmrInfoClassUrl: function (dealReferenceName, transactionReferenceName, classReferenceName) {
        return this.dealAmrInfoClass
            .replace(':dealReferenceName', dealReferenceName)
            .replace(':transactionReferenceName', transactionReferenceName)
            .replace(':classReferenceName', classReferenceName)
            .replace('//', '');
    },

    dealAmrInfoEdit: "/deals/:dealReferenceName?/amrInfo/:transactionReferenceName/edit",
    dealAmrInfoEditUrl: function (dealReferenceName, transactionReferenceName) {
        return this.dealAmrInfoEdit
            .replace(':dealReferenceName?', dealReferenceName || '')
            .replace(':transactionReferenceName', transactionReferenceName || '')
            .replace('//', '');
    },

    dealsAllClases: "/deals/:dealReferenceName?/allClasses/:mode(edit)?",
    dealsAllClasesUrl: function(dealReferenceName, edit = false) {
        return this.dealsAllClases
            .replace(':dealReferenceName?', dealReferenceName || '')
            .replace(':mode(edit)?', edit ? 'edit' : '')
            .replace('//', '');
    }
};
